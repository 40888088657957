<template>
  <div v-if="$route.path !== '/login' && isDataReady">
    <sidenav
      :orientation="orientation"
      :class="curClasses"
    >

      <!-- Inner -->
      <div
        class="sidenav-inner"
        :class="{ 'py-1': orientation !== 'horizontal' }"
        :active="isMenuActive('/cuadro-diario') || isMenuActive('/cuadro-general')"
        :open="isMenuOpen('/cuadro-diario') || isMenuOpen('/cuadro-general')"
      >
      <sidenav-router-link
            v-for="(items, index) in menuUsuario"
            :key="items.id"
            :id="index"
            icon="fa-solid fa-table-list"
            :to="{ path: '/' + items.href }"
            :exact="true"
            >{{ items.nombre }}</sidenav-router-link
          >
      </div>
    </sidenav>
  </div>
</template>
<style>
.sidenav-link {
  color: white !important;
}
.sidenav-item:hover {
  background-color:  rgb(204, 85, 51) !important;
}
.bg-sidenav-theme .sidenav-item.active > .sidenav-link:not(.sidenav-toggle) {
    background-color: rgb(204, 85, 51) !important;
}
</style>

<script>
import {
  getLanguageData
} from "../utilidades/setLanguageJSONLocalStorage.js";
import {
  Sidenav,
  SidenavLink,
  SidenavRouterLink,
  SidenavMenu,
  SidenavHeader,
  SidenavBlock,
  SidenavDivider
} from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    orientation: {
      type: String,
      default: "vertical"
    },
    idioma: {
      type: String
    }
  },
  data() {
    return {
      isDataReady: false,
      language: null,
      menuUsuario: {}
    }
  },
  watch: {
    // se escucha el cambio de idioma cuando se hace click en una bandera y se trae desde localStorage
    // el menu en el nuevo idioma seleccionado.
    idioma: function(newValue) {
      if (newValue) {
        this.isDataReady = false;
        this.menuUsuario = getLanguageData("menus", newValue).menuUsuario;
        this.isDataReady = true;
      }
    }
  },
  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (
        this.orientation === "horizontal" &&
        (bg.indexOf(" sidenav-dark") !== -1 ||
          bg.indexOf(" sidenav-light") !== -1)
      ) {
        bg = bg
          .replace(" sidenav-dark", "")
          .replace(" sidenav-light", "")
          .replace("-darker", "")
          .replace("-dark", "");
      }

      return (
        `bg-${bg} ` +
        (this.orientation !== "horizontal"
          ? "layout-sidenav"
          : "layout-sidenav-horizontal container-p-x flex-grow-0")
      );
    }
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return (
        this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal"
      );
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    }
  },
  created() {
    // se trae el idioma, el nombre del usuario y los textos de la navbar desde localStorage
    this.nombre = localStorage.getItem("name");
    this.language = localStorage.getItem("language");
    this.menuUsuario = getLanguageData("menus", this.language).menuUsuario;
    console.log("menu lateral", this.menuUsuario);
    this.isDataReady = true;
  }
};
</script>
