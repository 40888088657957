<template>
  <div v-if="$route.path !== '/login'">
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
      <div
        class="
          container-fluid
          d-flex
          flex-wrap
          justify-content-between
          text-center
          container-p-x
          pb-1
        "
      >
        <div class="pt-1 mt-3" style="font-size: 0.6rem">
          Diseñado por
          <a href="https://www.rodapro.es" target="_blank">
            <img
              src="/images/rodapro_logos/rodaproImagotipo.svg"
              weight="35"
              height="35"
              class="ml-1 mr-1 mb-2"
            />
          </a>
          © 2022
        </div>
        <div>
          <a href="http://rodamoto.es/" target="_blank" class="footer-link pt-3"
            >Sobre nosotros</a
          >
          <a href="http://rodamoto.es/content/2-aviso-legal" target="_blank" class="footer-link pt-3 ml-4"
            >Información legal</a
          >
          <a href="http://rodamoto.es/contactenos" target="_blank" class="footer-link pt-3 ml-4"
            >Contacto</a
          >
          <a href="http://rodamoto.es/content/10-politica-de-privacidad-y-lopd" target="_blank" class="footer-link pt-3 ml-4"
            >Política de privacidad y LOPD</a
          >
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "app-layout-footer",

  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`;
    }
  }
};
</script>
